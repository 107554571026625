export default {
  title: 'View rewards and get notified when new rewards are earned',
  or: 'or',
  downloadButton: 'Download the app',
  smsButton: {
    enable: 'Enable SMS',
    continue: 'Continue with SMS',
  },
  errors: {
    sms: 'We are having trouble messaging your number.',
  },
}
